/* eslint-disable */

import {
  Avatar,
  Badge,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Box,
  FormControl,
  Select,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  useBreakpointValue,
  ResponsiveValue,
  Input,
} from '@chakra-ui/react';
// Custom components
import { MdChevronRight, MdChevronLeft, MdMoreHoriz } from 'react-icons/md';
import * as React from 'react';
// Assets
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

import {
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { IExam } from '../../Redux/Exam/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPageNo,
  selectPageSize,
  selectLoading,
  selectTotalRow,
  selectSearch,
  selectSortColumn,
  selectSortDir,
} from '../../Redux/Exam/selector';
import { actions } from '../../Redux/Exam/slice';
import dateFormat from 'components/hooks/dateFormat';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

type Props = { rData: IExam[] };

export default function List({ rData }: Props) {
  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, [rData]);

  const pagesize = useSelector(selectPageSize);
  const loading = useSelector(selectLoading);
  const totalCount = useSelector(selectTotalRow);
  const SortColumn = useSelector(selectSortColumn);
  const SortDir = useSelector(selectSortDir);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(actions.doGetList());
    return () => {};
  }, [SortDir, SortColumn]);

  const dispatch = useDispatch();
  const pageNo = useSelector(selectPageNo);

  React.useEffect(() => {
    dispatch(actions.setPageNo(1));
    dispatch(actions.setPageSize(10));
    dispatch(actions.setSearch(''));
    return () => {};
  }, []);
  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, []);

  const handleViewStudent = (id: string) => {
    navigate(`/admin/student-list/${id}`);
  };
  const handleViewPayment = (id: string) => {
    navigate(`/admin/payment-list/${id}`);
  };

  const textColor = useColorModeValue('brand.500', 'white');
  const borderColor = useColorModeValue('secondaryGray.600', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const [globalFilter, setGlobalFilter] = React.useState('');
  const columnHelper = createColumnHelper<IExam>();
  const columns = [
    columnHelper.accessor('title', {
      id: 'title',
      header: 'Title',

      cell: (info) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>
              {info.getValue()?.length > 40
                ? info.getValue()?.slice(0, 40)
                : info.getValue()}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('university_title', {
      id: 'university_title',
      enableSorting: true,
      header: 'University Title',
      sortDescFirst: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor('isPublished', {
      id: 'isPublished',
      header: 'Published',

      cell: (info) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>{info.getValue() ? 'Yes' : 'No'}</Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('examDate', {
      id: 'examDate',
      header: 'Exam Date',
      cell: (info) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>{dateFormat(info?.getValue())}</Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('totalStudents', {
      id: 'totalStudents',
      header: 'Total Students',
      cell: (info) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>{info?.getValue()}</Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: 'Created At',
      cell: (info) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>{dateFormat(info?.getValue())}</Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('_id', {
      id: '_id',
      header: 'Action',
      cell: (info) => (
        <Menu size={'10px'}>
          <MenuButton>
            <Text marginLeft={2} fontSize={21} cursor={'pointer'}>
              ...
            </Text>
          </MenuButton>
          <MenuList>
            <MenuItem
              px="14px"
              onClick={() => {
                handleViewStudent(info.getValue());
              }}
            >
              View Student
            </MenuItem>
            <MenuItem
              px="14px"
              onClick={() => {
                handleViewPayment(info.getValue());
              }}
            >
              View Payment
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    }),
  ];
  const [sorting, setSorting] = React.useState([]);
  const table = useReactTable({
    data: rData,
    columns: columns,
    state: {
      globalFilter,
      sorting,
    },
    pageCount: Math.ceil(totalCount / pagesize),
    sortDescFirst: true,
    enableMultiSort: true,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  React.useEffect(() => {
    if (sorting.length > 0) {
      dispatch(actions.setSortColumn(sorting[0].id));
      dispatch(actions.setSortDir(sorting[0].desc ? 'desc' : 'asc'));
    }
    return () => {};
  }, [sorting]);
  const createPages = (
    count: number,
    currentPage: number,
    maxButtons: number,
  ) => {
    let arrPageCount: any = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, count);
    startPage = Math.max(endPage - maxButtons + 1, 1);

    for (let i = startPage; i <= endPage; i++) {
      arrPageCount.push(i);
    }

    if (startPage > 1) {
      arrPageCount.unshift('...');
    }
    if (endPage < count) {
      arrPageCount.push('...');
    }

    return arrPageCount;
  };

  // In your component
  const pageCount = table.getPageCount();
  const pages = createPages(pageCount, pageNo, 2);
  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name') {
        table.setSorting([{ id: 'name', desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);
  console.log('Disabled:', pageNo === 1 || rData.length === 0);
  const flexDirection: ResponsiveValue<'row' | 'column'> = useBreakpointValue({
    base: 'column',
    md: 'row',
  });
  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex align={'center'} justify={'space-between'} w="100%" mb="28px">
          <DebouncedInput
            className="p-2 font-lg shadow border border-block"
            placeholder="Search..."
          />
        </Flex>
        {loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <>
            <Box overflowX="auto" mb="24px">
              {table.getRowModel().rows.length > 0 ? (
                <Table variant="simple" color="gray.500" minWidth="600px">
                  <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header: any) => {
                          return (
                            <Th
                              pe="10px"
                              borderColor={borderColor}
                              key={header.id}
                              colSpan={header.colSpan}
                              style={{
                                fontWeight: 500,
                                cursor: 'pointer',
                                textTransform: 'capitalize',
                                color: textColor,
                              }}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </Th>
                          );
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row: any) => {
                      return (
                        <Tr px="20px" key={row.id}>
                          {row.getVisibleCells().map((cell: any) => {
                            return (
                              <Td
                                key={cell.id}
                                color={textColor}
                                fontSize={{ sm: '12px', md: '14px' }}
                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                borderColor={borderColor}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Text
                  fontSize="sm"
                  color={textColor}
                  fontWeight="normal"
                  textAlign={'center'}
                  marginRight={20}
                >
                  {' '}
                  No Records Available
                </Text>
              )}
            </Box>
            {rData.length != 0 && !loading && (
              <Flex
                w="100%"
                direction={flexDirection}
                justify="space-between"
                align={'center'}
                px="20px"
                pt="10px"
                pb="5px"
              >
                <Box>
                  <Stack
                    direction="row"
                    alignSelf="flex-end"
                    alignItems={'center'}
                    spacing="4px"
                    ms="auto"
                  >
                    <Text
                      fontSize="sm"
                      color={textColor}
                      fontWeight="normal"
                      marginRight={2}
                    >
                      Show
                    </Text>
                    <FormControl
                      style={{
                        marginLeft: 0,
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Select
                        variant="outline"
                        color={textColor}
                        borderWidth={0.5}
                        width={20}
                        height={8}
                        value={pagesize}
                        borderRadius={4}
                        _hover={{
                          cursor: 'pointer',
                        }}
                        _focus={{
                          cursor: 'pointer',
                          outline: 'none',
                        }}
                        _active={{
                          cursor: 'pointer',
                          outline: 'none',
                        }}
                        focusBorderColor="gray.500"
                        onChange={(e) => {
                          dispatch(actions.setPageNo(1));
                          dispatch(actions.setPageSize(Number(e.target.value)));
                        }}
                      >
                        {[5, 10, 20, 40, 50, 100, 200].map((data) => (
                          <option style={{ cursor: 'pointer' }}>{data}</option>
                        ))}
                      </Select>
                    </FormControl>
                    <Text
                      fontSize="sm"
                      color={textColor}
                      fontWeight="normal"
                      marginLeft={2}
                    >
                      entries
                    </Text>
                  </Stack>
                </Box>
                <div className="flex items-center gap-2">
                  <Stack
                    direction="row"
                    alignSelf="flex-end"
                    spacing="4px"
                    ms="auto"
                  >
                    <Button
                      variant="no-effects"
                      onClick={() => {
                        if (pageNo === 1 || rData.length === 0) {
                          return;
                        }
                        dispatch(actions.setPageNo(pageNo - 1));
                      }}
                      disabled={pageNo === 1 || rData.length === 0}
                      transition="all .5s ease"
                      color={
                        pageNo === 1 || rData.length === 0 ? 'gray' : textColor
                      }
                      isDisabled={pageNo === 1 || rData.length === 0}
                      fontWeight={400}
                      fontSize={14}
                      h="28px"
                      borderRadius="8px"
                      bg="transparent"
                    >
                      <Icon
                        as={MdChevronLeft}
                        w="26px"
                        h="26px"
                        color={textColor}
                      />
                      Previous
                    </Button>

                    {Array.isArray(pages) &&
                      pages.map((pageNumber, index) => {
                        return (
                          <Button
                            variant="no-effects"
                            transition="all .5s ease"
                            onClick={() => {
                              if (pageNumber !== '...') {
                                dispatch(actions.setPageNo(Number(pageNumber)));
                              }
                            }}
                            w="28px"
                            h="28px"
                            minW={'auto'}
                            borderRadius="8px"
                            padding={'6px'}
                            fontWeight={400}
                            bg={
                              pageNumber === pageNo ? brandColor : 'transparent'
                            }
                            key={index}
                          >
                            <Text
                              fontSize="sm"
                              color={pageNumber === pageNo ? '#fff' : textColor}
                            >
                              {pageNumber}
                            </Text>
                          </Button>
                        );
                      })}
                    <Button
                      variant="no-effects"
                      onClick={() => {
                        if (pageNo >= Math.ceil(totalCount / pagesize)) {
                          return;
                        }
                        dispatch(actions.setPageNo(pageNo + 1));
                      }}
                      disabled={pageNo >= Math.ceil(totalCount / pagesize)}
                      transition="all .5s ease"
                      color={
                        pageNo >= Math.ceil(totalCount / pagesize)
                          ? 'gray'
                          : textColor
                      }
                      isDisabled={pageNo >= Math.ceil(totalCount / pagesize)}
                      fontWeight={400}
                      fontSize={14}
                      h="28px"
                      borderRadius="8px"
                      bg="transparent"
                    >
                      Next
                      <Icon
                        as={MdChevronRight}
                        w="26px"
                        h="26px"
                        color={textColor}
                      />
                    </Button>
                  </Stack>
                </div>

                <Text
                  fontSize="sm"
                  color={textColor}
                  fontWeight="normal"
                  mb={{ sm: '24px', md: '0px' }}
                >
                  Page {pageNo} to{' '}
                  {pageNo > 1
                    ? pageNo * pagesize > totalCount
                      ? totalCount
                      : pageNo * pagesize
                    : pagesize}{' '}
                  of {totalCount}
                </Text>
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  );
}
// A debounced input react component
function DebouncedInput({
  debounce = 500,
}: {
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  useEffect(() => {
    if (search.length > 0) {
      dispatch(actions.setPageNo(1));
    }
    return () => {};
  }, [search]);
  const handleChange = (e: any) => {
    dispatch(actions.setSearch(e.target.value));
  };
  return (
    <>
      <SearchBar
        name="search"
        value={search}
        onChange={handleChange}
        h="36px"
        w={{ sm: '190px', lg: '280px' }}
        borderRadius="4px"
      />
    </>
  );
}
