import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRegistrationDetails,
  selectLoading,
} from '../../Redux/Exam/selector';
import { ENUM_ADDITIONAL_FIELD_TYPE } from '../../Redux/Exam/types';
import { actions } from '../../Redux/Exam/slice';
import { path } from '../../utils/Api';
import DateFormat from 'components/hooks/dateFormat';
import { Box, Grid, GridItem, Spinner, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const RegistrationDetails: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const data = useSelector(selectRegistrationDetails);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    if (params?.id) {
      dispatch(
        actions.doGetRegistrationDetails({ id: params.id, callback() {} }),
      );
    }
    return () => {};
  }, []);
  const handleOpen = (data: string) => {
    window.open(`${path()}${data}`);
  };
  return (
    <>
      {loading ? (
        <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <Grid gap={6} rowGap={{ xs: 2, md: '18px' }}>
          <GridItem>
            <Heading
              sx={{
                fontSize: 20,
                fontWeight: 600,
                textDecoration: 'underline',
              }}
            >
              Application information entry
            </Heading>
          </GridItem>
          {data?.profilePic && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)" gap={6} sx={gridHeadStyle}>
                <GridItem>
                  <Heading>Profile Pic</Heading>
                </GridItem>
                <GridItem>
                  <UploadingFile sx={uploadedFilesStyle}>
                    <img
                      src={`${path()}${data?.profilePic}`}
                      alt={data?.profilePic}
                      //@ts-ignore
                      style={imgStyle}
                    />
                  </UploadingFile>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.name1 && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)" gap={6} sx={gridHeadStyle}>
                <GridItem>
                  <Heading>Name 1</Heading>
                </GridItem>
                <GridItem>
                  <HeadingSecond>{data?.name1}</HeadingSecond>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.name2 && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)" gap={6} sx={gridHeadStyle}>
                <GridItem>
                  <Heading>Name 2</Heading>
                </GridItem>
                <GridItem>
                  <HeadingSecond>{data?.name2}</HeadingSecond>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.address && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)" sx={gridHeadStyle}>
                <GridItem>
                  <Heading>Address</Heading>
                </GridItem>
                <GridItem>
                  <HeadingSecond>{data?.address}</HeadingSecond>
                </GridItem>
              </Grid>
            </GridItem>
          )}{' '}
          {data?.nameOfHighSchool && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)"sx={gridHeadStyle}>
                <GridItem>
                  <Heading>High school attended</Heading>
                </GridItem>
                <GridItem>
                  <HeadingSecond>{data?.nameOfHighSchool}</HeadingSecond>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.department && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)">
                <GridItem>
                  <Heading>Department</Heading>
                </GridItem>
                <GridItem>
                  <HeadingSecond>{data?.department}</HeadingSecond>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.dateOfGraduation && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)">
                <GridItem>
                  <Heading>Date of graduation</Heading>
                </GridItem>
                <GridItem>
                  <HeadingSecond>
                    {DateFormat(data?.dateOfGraduation)}
                  </HeadingSecond>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.dateOfBirth && (
            <GridItem sx={gridItemStyle}>
              <Grid templateColumns="repeat(6, 1fr)">
                <GridItem>
                  <Heading>Date of Birth</Heading>
                </GridItem>
                <GridItem>
                  <HeadingSecond>{DateFormat(data?.dateOfBirth)}</HeadingSecond>
                </GridItem>
              </Grid>
            </GridItem>
          )}
          {data?.additionalFieldsData?.map((item, index) => {
            return (
              <GridItem sx={gridItemStyle} key={index}>
                <Grid templateColumns="repeat(6, 1fr)">
                  <Heading sx={optionHeadingStyle}>{item.title}</Heading>
                </Grid>
                <Grid templateColumns="repeat(3, 1fr)">
                  {item?.type == ENUM_ADDITIONAL_FIELD_TYPE.TEXT && (
                    <>
                      <HeadingSecond>{item.values}</HeadingSecond>
                    </>
                  )}
                  {item?.type == ENUM_ADDITIONAL_FIELD_TYPE.TEXT_AREA && (
                    <HeadingSecond>{item.values}</HeadingSecond>
                  )}
                  {(item?.type == ENUM_ADDITIONAL_FIELD_TYPE.SINGLE_SELECT ||
                    item?.type == ENUM_ADDITIONAL_FIELD_TYPE.SELECT_YES_NO) && (
                    <HeadingSecond>{item?.values}</HeadingSecond>
                  )}
                  {item?.type == ENUM_ADDITIONAL_FIELD_TYPE.MULTI_SELECT && (
                    <>
                      <HeadingSecond>
                        {item.values.map((ele: any, index: number) => {
                          return (
                            <>
                              {ele} {index - 1 ? <>,</> : ''}
                            </>
                          );
                        })}
                      </HeadingSecond>
                    </>
                  )}
                  {item?.type == ENUM_ADDITIONAL_FIELD_TYPE.FILE_SELECT && (
                    <>
                      <UploadedFilesBox>
                        {item.values.map((x: any, i: number) => {
                          return (
                            <UploadingFile
                              key={i}
                              sx={uploadedFilesStyle1}
                              onClick={() => handleOpen(x)}
                            >
                              {x}
                            </UploadingFile>
                          );
                        })}
                      </UploadedFilesBox>
                    </>
                  )}
                </Grid>
              </GridItem>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default RegistrationDetails;

const Heading = styled(Text)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#2E2E2E',
}));
const HeadingSecond = styled(Text)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#5780AD',
}));

const gridItemStyle = {
  borderBottom: '1px solid #DEDEDE',
  paddingBottom: { xs: '9px', md: '15px' },
  // alignItems: 'center',
};

const gridHeadStyle = {
  alignItems: 'center',
};

const optionHeadingStyle = {
  marginBottom: 2,
};

const UploadingFile = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: '#000',
  padding: '10px 7.8px',
  border: '0.5px solid #E3E3E3',
  borderRadius: 5,
  marginLeft: '15px',
}));
const uploadedFilesStyle = {
  borderColor: '#11AF22',
  marginBottom: '5px',
  width: '160px',
  height: '185px',
};
const uploadedFilesStyle1 = {
  borderColor: '#11AF22',
  marginBottom: '5px',
  cursor: 'pointer',
};
const imgStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
};
const UploadedFilesBox = styled(Text)(({ theme }) => ({
  marginTop: 28.5,
}));
