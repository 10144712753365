export interface IUniversity {
  _id: string;
  title: string;
  email: string;
  address: string;
  description: string;
  isActive: ENUM_USER_STATUS;
  createdAt: string;
  updatedAt: string;
  user: string;
  seoTitle: string;
  totalStudents: number;
}

export enum StatusTypeEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}
export enum ENUM_USER_STATUS {
  ACTIVE = 1,
  INACTIVE = 2,
  DELETED = 3,
}

export interface UniversityState {
  form: IUniversity;
  list: IUniversity[];
  loading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  addNew: boolean;
  buttonLoading: boolean;
  editLoading: boolean;
}

export type InitialState = UniversityState;
