import {
  Box,
  Card,
  Grid,
  useColorModeValue,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import Temperature from '../smartHome/components/Temperature';
import User from 'assets/img/newimages/People.svg';
import Shape from 'assets/img/newimages/Shape.svg';
import Shape1 from 'assets/img/newimages/Shape1.svg';
import Team from 'assets/img/newimages/People_Community.svg';
import { Icon, Image } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';
import { useEffect, useState } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
//@ts-ignore
import DatePicker from 'react-datepicker';
import { Input } from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import { DateFilterTypeEnum } from '../../../../Redux/DashBoard/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectChartData,
  SelectDashboardFormData,
  selectEndDate,
  selectFilterValue,
  selectStartDate,
} from '../../../../Redux/DashBoard/selector';
import { actions } from '../../../../Redux/DashBoard/slice';

export default function Default() {
  const chartData = useSelector(SelectChartData);
  const textColor = useColorModeValue('brand.500', 'white');
  const secondary = useColorModeValue('secondaryGray.600', 'white');

  
  const [series, setSeries] = useState([
    {
      name: 'University',
      data: chartData.university || [],
    },
    {
      name: 'Exam',
      data: chartData.exam || [],
    },
    {
      name: 'Student',
      data: chartData.student || [],
    },
  ]);
  useEffect(() => {
    setSeries([
      {
        name: 'University',
        data: chartData.university || [],
      },
      {
        name: 'Exam',
        data: chartData.exam || [],
      },
      {
        name: 'Student',
        data: chartData.student || [],
      },
    ]);
  }, [chartData]);
  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        style: {
          fontSize: '12px',
        },
      },
      theme: 'light',
    },
    xaxis: {
      categories: chartData.date,
      show: false,
      labels: {
        show: true,
        style: {
          colors: secondary,
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: 'black',
      labels: {
        show: true,
        style: {
          colors: '#CBD5E0',
          fontSize: '14px',
        },
      },
    },
    grid: {
      show: true,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: 'white',
              opacity: 1,
            },
            {
              offset: 100,
              color: 'white',
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '40px',
        margin: 2,
      },
    },
  };
  const newOptions = {
    ...barChartOptionsDailyTraffic,
    chart: {
      padding: {
        top: 40, // Add padding to the top
        right: 40, // Add padding to the right
        bottom: 40, // Add padding to the bottom
        left: 20, // Add padding to the left
      },
      // background: '#21232E', // Set the background color of the chart
      zoom: {
        enabled: true,
        type: 'xy',
        zoomedArea: {
          fill: '#ffffff',
          stroke: '#0D47A1',
          strokeWidth: 1,
        },
      },
    },
    fill: {
      ...barChartOptionsDailyTraffic.fill,
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: textColor,
              opacity: 1,
            },
          ],
          [
            {
              offset: 0,
              color: 'gray',
              opacity: 1,
            },
          ],
          [
            {
              offset: 0,
              color: '#5780AD',
              opacity: 1,
            },
          ],
        ],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%', // Adjust the width of the column (bar)
        endingShape: 'flat',
        barWidth: 0.5, // Set the width of each bar
        borderRadiusTopLeft: 10, // Set the radius of the top-left corner
        borderRadiusTopRight: 10, // Set the radius of the top-right corner
        offsetY: 20, // Add a gap between the bars
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#8C89B4', // Set the color of the labels
        },
      },
      axisTicks: {
        show: true,
        color: '#8C89B4', // Set the color of the ticks
      },
      grid: {
        show: true, // Display horizontal grid lines
        borderColor: '#FFFFFF', // Set the color of the grid lines to white
        strokeDashArray: 3, // Set the stroke dash array for the grid lines
        position: 'back', // Position the grid lines behind the bars
      },
    },
  };

  const dispatch = useDispatch();
  const handleChange = (dates: any) => {
    const [start, end] = dates;
    dispatch(actions.setStartDate(start));
    if (end == null) {
      dispatch(actions.setEndDate(''));
    } else {
      dispatch(actions.setEndDate(end));
    }

    dispatch(actions.setFilterValue(-1));
  };

  const gridTemplateColumns = useBreakpointValue({
    sm: 'repeat(3, 1fr)',
    md: 'repeat(5, 1fr)',
    lg: 'repeat(5, 1fr)',
    xl: 'repeat(5, 1fr)',
    '2xl': 'repeat(5, 1fr)',
  });
  const filterSelect = (filter: number) => {
    dispatch(actions.setFilterValue(filter));
  };

  const endDate = useSelector(selectEndDate);
  const startDate = useSelector(selectStartDate);
  const filterValue = useSelector(selectFilterValue);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      dispatch(actions.doGetDashboardList());
      dispatch(actions.getDashboardChartData());
    }

    return () => {};
  }, [filterValue, startDate, endDate]);

  useEffect(() => {
    dispatch(actions.doGetDashboardList());
    dispatch(actions.getDashboardChartData());
    return () => {};
  }, []);
  const data = useSelector(SelectDashboardFormData);
  function calculatePercentageChange(
    current: number,
    previous: number,
  ): string {
    // If both current and previous values are zero, return an empty string
    if (current === 0 && previous === 0) {
      return '';
    }

    const difference = current - previous;
    const total = current + previous;
    // If the difference is zero, return an empty string
    if (difference === 0) {
      return '';
    }
    const percentageChange = (current * 100) / total;

    const sign = difference > 0 ? '+' : '-';
    const formattedPercentage = `${sign}${Math.abs(percentageChange).toFixed(
      0,
    )}%`;
    console.log(formattedPercentage, percentageChange, 'formattedPercentage');

    return formattedPercentage;
  }

  const today = new Date();

  const filters = [
    { label: 'All', value: DateFilterTypeEnum.ALL },
    { label: 'Today', value: DateFilterTypeEnum.TODAY },
    { label: 'Week', value: DateFilterTypeEnum.WEEK },
    { label: 'Last Month', value: DateFilterTypeEnum.MONTH },
    { label: 'Year', value: DateFilterTypeEnum.YEAR },
  ];

  const displayStyle = useBreakpointValue({ base: 'block', md: 'flex' });

  const justifyContentStyle = useBreakpointValue({
    base: 'center',
    md: 'flex-end',
  });

  const alignItemsStyle = useBreakpointValue({
    base: 'center',
    md: 'initial',
  });

  const gapMobile = '4'; // Vertical gap for mobile
  const gapWeb = '5'; // Horizontal gap for web

  const gap = useBreakpointValue({ base: gapMobile, md: gapWeb });
  return (
    <>
      <Grid
        display={displayStyle}
        gap={gap}
        justifyContent={justifyContentStyle}
        alignItems={alignItemsStyle}
        pt={{ base: '130px', md: '80px', xl: '80px' }}
        gridTemplateColumns={{
          base: 'repeat(2, 1fr)',
        }}
      >
        <Flex
          direction="row"
          align="center"
          justify="center"
          borderRadius="md"
          overflow="hidden"
          marginX="-1px" // Adjust margin to overlap borders
        >
          {filters.map((filter, index) => (
            <Button
              key={filter.value}
              padding={5}
              marginLeft={2}
              variant={filterValue === filter.value ? 'brand' : 'outline'}
              cursor="pointer"
              onClick={() => filterSelect(filter.value)}
              color={filterValue === filter.value ? secondary : textColor}
              _hover={{
                color: '#fff',
                bg: 'brand.500',
              }}
            >
              {filter.label}
            </Button>
          ))}
        </Flex>
        <Box
          marginTop={{ base: '10px', md: '0px', xl: '0px' }}
          alignSelf={'center'}
          justifyContent={'center'}
          display={'flex'}
        >
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            maxDate={today}
            dateFormat="d MMM yyyy"
            customInput={
              <Input
                readOnly
                bg="#21232E"
                height="44px"
                border="0px"
                color="white"
                _hover={{
                  border: 'none',
                }}
                cursor={'pointer'}
                _focus={{
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                }}
                _active={{
                  border: 'none',
                  outline: 'none',
                  boxShadow: 'none',
                }}
                sx={{
                  width: '-webkit-fill-available',
                }}
              />
            }
          />
        </Box>
      </Grid>
      <Grid
        pt={{ base: '30px' }}
        mb="20px"
        gridTemplateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(3, 1fr)',
          '2xl': 'repeat(4, 1fr)',
        }}
        gap={{ base: '20px', xl: '20px' }}
        display="grid"
      >
        <Temperature
          icon={
            <Image src={Shape} width="25px" height="25px" color="inherit" />
          }
          headLine={'Total Income'}
          amount={data?.totalPayment?.toFixed()?.toString()}
          isDown={false}
          percentValue={calculatePercentageChange(
            data.totalPayment,
            data.previousTotalPayment,
          )}
          isAmount={true}
        />
        <Temperature
          icon={
            <Image src={Shape1} width="25px" height="25px" color="inherit" />
          }
          headLine={'Active University'}
          amount={data?.universityCount?.toFixed()?.toString()}
          isDown={true}
          percentValue={calculatePercentageChange(
            data.universityCount,
            data.previousUniversityCount,
          )}
        />
        <Temperature
          icon={<Image src={User} width="25px" height="25px" color="inherit" />}
          headLine={'Total Student'}
          amount={data?.studentCount?.toFixed()?.toString()}
          isDown={false}
          percentValue={calculatePercentageChange(
            data.studentCount,
            data.previousStudentCount,
          )}
        />
        <Temperature
          icon={<Image src={Team} width="25px" height="25px" color="inherit" />}
          headLine={'Total Exam'}
          amount={data?.examCount?.toFixed()?.toString()}
          isDown={false}
          percentValue={calculatePercentageChange(
            data?.examCount,
            data?.previousExamCount,
          )}
        />
      </Grid>
      <Card>
        {' '}
        <Box
          h="530px"
          display="block"
          alignItems="center"
          borderRadius={'20px'}
          padding={'20px'}
        >
          <Text
            fontSize={'28px'} // Font size decreases as screen size decreases
            lineHeight="10"
            color={textColor}
            marginBottom={5}
            fontWeight="bold"
            style={{
              marginLeft: 5,
              textAlign: 'left',
            }}
          >
            Analytics
          </Text>
          <Box width="100%" height={'87%'}>
            <BarChart chartData={series} chartOptions={newOptions} />
            <style>
              {`
            .apexcharts-menu-icon {
              display: none;
            }
          `}
            </style>
          </Box>
        </Box>
      </Card>
    </>
  );
}
