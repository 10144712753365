import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { selectButtonLoading } from '../../Redux/University/selector';
import FooterButtons from 'components/FooterButtons/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React from 'react';
import { useSelector } from 'react-redux';

interface Props {
  id?: string;
  open: boolean;
  title?: string;
  buttonTitle: string;
  content: string;
  handleClose: any;
  handleClick: any;
}

const ConfirmModal: React.FC<Props> = ({
  buttonTitle,
  content,
  handleClick,
  handleClose,
  open,
  id,
  title,
}) => {
  const textColor = useColorModeValue('secondaryGray.600', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const textColorSecondary = 'gray.400';
  const loading = useSelector(selectButtonLoading);

  return (
    <>
      <ModalTemplate title={title} open={open} handleClose={handleClose}>
        <Box>
          <Text fontSize={'x-large'} color={textColor}>{content}</Text>
        </Box>
        <FooterButtons
          title={buttonTitle}
          loading={loading}
          handleCancelButton={handleClose}
          handleClick={handleClick}
        />
      </ModalTemplate>
    </>
  );
};

export default ConfirmModal;
