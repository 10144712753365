import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { selectEndDate, selectFilterValue, selectStartDate } from './selector';
import CatchBlockFunction from 'components/hooks/CatchError';
import { get } from 'utils/request';
import moment from 'moment';
import { toast } from 'sonner';
export function* getDashboardListRequest() {
  yield delay(500);
  const dateFIlter: number = yield select(selectFilterValue);
  const startDate: Date | string = yield select(selectStartDate);
  const endDate: Date | string = yield select(selectEndDate);

  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/dashboard/get/data?dateFilter=${
        dateFIlter == -1 ? '' : dateFIlter
      }&startDate=${
        startDate !== '' && dateFIlter == -1
          ? moment(startDate).format('YYYY/MM/DD')
          : ''
      }&endDate=${
        endDate !== '' && dateFIlter == -1
          ? moment(endDate).format('YYYY/MM/DD')
          : ''
      }`,
    );
    if (response && response.data.status == false) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDashboardsList(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* getDashboardChartDataRequest() {
  yield delay(500);
  const dateFIlter: number = yield select(selectFilterValue);
  const startDate: Date | string = yield select(selectStartDate);
  const endDate: Date | string = yield select(selectEndDate);
  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/dashboard/dashboard-graph?dateFilter=${
        dateFIlter == -1 ? '' : dateFIlter
      }&startDate=${
        startDate !== '' && dateFIlter == -1
          ? moment(startDate).format('YYYY/MM/DD')
          : ''
      }&endDate=${
        endDate !== '' && dateFIlter == -1
          ? moment(endDate).format('YYYY/MM/DD')
          : ''
      }`,
    );
    if (response && response.data.status == false) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setChartData(response.data.data));
  } catch (error: any) {
    CatchBlockFunction(error);
  }
}

export function* DashboardRepoSaga() {
  yield takeLatest(actions.doGetDashboardList, getDashboardListRequest);
  yield takeLatest(actions.getDashboardChartData, getDashboardChartDataRequest);
}
