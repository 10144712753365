import { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import RegistrationDetails from './RegistrationDetails';
import SubmissionMailingDetails from './SubmissionMailingDetails';

import { useNavigate, useParams } from 'react-router-dom';
import { actions } from '../../Redux/Exam/slice';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExamFeeDetailsData,
  selectExamResultDetailsData,
  selectExamDateDetailsData,
  selectFeeLoading,
  selectResultLoading,
  selectExamDateLoading,
  selectExamPaymentDetailsData,
} from '../../Redux/Exam/selector';
import dateFormat from 'components/hooks/dateFormat';
import { PaymentTypeEnum } from '../../Redux/Exam/types';

const Details = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const feeData = useSelector(selectExamFeeDetailsData);
  const rData = useSelector(selectExamResultDetailsData);
  const eData = useSelector(selectExamDateDetailsData);
  const pData = useSelector(selectExamPaymentDetailsData);
  const feeLoading = useSelector(selectFeeLoading);
  const rLoading = useSelector(selectResultLoading);
  const eLoading = useSelector(selectExamDateLoading);

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.doGetFeeDetails({ id: params.examId, callback() {} }));
      dispatch(
        actions.doGetExamDateDetails({ id: params.examId, callback() {} }),
      );
      dispatch(actions.doGetResultDetails({ id: params.id, callback() {} }));
      dispatch(actions.doGetPaymentDetails({ id: params.id, callback() {} }));
    }
    return () => {};
  }, []);
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card>
        <Box p={5}>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              fontSize="sm"
              variant="outline"
              fontWeight="400"
              h="40px"
              onClick={handleBack}
            >
              Cancel
            </Button>
          </Box>
          <RegistrationDetails />
          <Divider mb={4} />
          <SubmissionMailingDetails />
          <Divider mb={4} />
          {feeLoading ? (
            <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
              <Spinner color="brand.500" />
            </Box>
          ) : (
            <Grid gap={6} rowGap={{ xs: 2, md: '18px' }}>
              <GridItem>
                <Heading
                  sx={{
                    fontSize: 20,
                    fontWeight: 'bold !important',
                    textDecoration: 'underline',
                  }}
                >
                  PASS/FAIL INFORMATION
                </Heading>
              </GridItem>

              <GridItem sx={gridItemStyle}>
                <Grid
                  templateColumns="repeat(6, 1fr)"
                  gap={6}
                  sx={gridHeadStyle}
                >
                  <GridItem>
                    <Heading>Marks</Heading>
                  </GridItem>
                  <GridItem>
                    <HeadingSecond>{rData?.marks}</HeadingSecond>
                  </GridItem>
                </Grid>
              </GridItem>

              {rData?.resultContent && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Result Content</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>{rData?.resultContent}</HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
            </Grid>
          )}{' '}
          <Divider mb={4} />
          {eLoading ? (
            <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
              <Spinner color="brand.500" />
            </Box>
          ) : (
            <Grid gap={6} rowGap={{ xs: 2, md: '18px' }}>
              <GridItem>
                <Heading
                  sx={{
                    fontSize: 20,
                    fontWeight: 'bold !important',
                    textDecoration: 'underline',
                  }}
                >
                  Date of examination
                </Heading>
              </GridItem>
              {eData?.examDate && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Exam Date</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>
                        {dateFormat(eData?.examDate)}
                      </HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
              {eData?.examPlace && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Address</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>{eData?.examPlace}</HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
              {eData?.examDateRemark && (
                <GridItem sx={gridItemStyle}>
                  <Grid templateColumns="repeat(6, 1fr)" sx={gridHeadStyle}>
                    <GridItem>
                      <Heading>Exam Date Remark</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>{eData?.examDateRemark}</HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}{' '}
            </Grid>
          )}{' '}
          <Divider mb={4} />
          {rLoading ? (
            <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
              <Spinner color="brand.500" />
            </Box>
          ) : (
            <Grid gap={6} rowGap={{ xs: 2, md: '18px' }}>
              <GridItem>
                <Heading
                  sx={{
                    fontSize: 20,
                    fontWeight: 'bold !important',
                    textDecoration: 'underline',
                  }}
                >
                  Fee Details
                </Heading>
              </GridItem>
              {feeData?.feeLastDate && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Fee Last Date</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>
                        {dateFormat(feeData?.feeLastDate)}
                      </HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
              {feeData?.amount && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Amount</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>{feeData?.amount}</HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
            </Grid>
          )}{' '}
          <Divider mb={4} />
          {rLoading ? (
            <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
              <Spinner color="brand.500" />
            </Box>
          ) : (
            <Grid gap={6} rowGap={{ xs: 2, md: '18px' }}>
              <GridItem>
                <Heading
                  sx={{
                    fontSize: 20,
                    fontWeight: 'bold !important',
                    textDecoration: 'underline',
                  }}
                >
                  Payment Details
                </Heading>
              </GridItem>
              {pData?.paymentId && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Payment Id</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>{pData.paymentId}</HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
              {pData?.paymentMethod && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Payment Method</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>
                        {pData.paymentMethod == PaymentTypeEnum.CARD
                          ? 'Card'
                          : 'Department'}
                      </HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
              {pData?.amount && (
                <GridItem sx={gridItemStyle}>
                  <Grid
                    templateColumns="repeat(6, 1fr)"
                    gap={6}
                    sx={gridHeadStyle}
                  >
                    <GridItem>
                      <Heading>Amount</Heading>
                    </GridItem>
                    <GridItem>
                      <HeadingSecond>{pData?.amount}</HeadingSecond>
                    </GridItem>
                  </Grid>
                </GridItem>
              )}
            </Grid>
          )}
        </Box>
      </Card>
    </Flex>
  );
};

export default Details;
const gridItemStyle = {
  borderBottom: '1px solid #DEDEDE',
  paddingBottom: { xs: '9px', md: '15px' },
  // alignItems: 'center',
};
const Heading = styled(Text)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#2E2E2E',
}));
const HeadingSecond = styled(Text)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#5780AD',
}));
const gridHeadStyle = {
  alignItems: 'center',
};
