import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  selectButtonLoading,
  selectEditLoading,
  selectForm,
} from '../../Redux/University/selector';
import { actions } from '../../Redux/University/slice';
import FooterButtons from 'components/FooterButtons/Index';
import ModalTemplate from 'components/ModalTemplate/Index';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ENUM_USER_STATUS, StatusTypeEnum } from '../../Redux/University/types';
import TextField from 'components/fields/TextField';

interface props {
  id: string;
  open: boolean;
  handleClose: any;
}

function Add(props: props) {
  const bgPrimary = useColorModeValue('transparent', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const form = useSelector(selectForm);
  const buttonLoading = useSelector(selectButtonLoading);
  const loading = useSelector(selectEditLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.id) {
      dispatch(actions.doGetEdit(props.id));
    }
    return () => {};
  }, [props.id]);

  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };

  const handleSelectChange = (evt: any) => {
    dispatch(
      actions.updateFormValue({ key: 'isActive', value: Number(evt.target.value) }),
    );
  };

  const handleSubmit = () => {
    dispatch(actions.setSearch(''));
    if (props?.id) {
      dispatch(
        actions.doUpdate({
          callback: () => {
            props?.handleClose();
            dispatch(actions.doGetList());
          },
        }),
      );
    } else {
      dispatch(
        actions.doAdd({
          callback: () => {
            props?.handleClose();
            dispatch(actions.doGetList());
          },
        }),
      );
    }
  };
  useEffect(() => {
    if (props.id) {
      dispatch(actions.setLoading(true));
    }
    return () => {};
  }, [props.id]);

  return (
    <>
      <ModalTemplate
        title={props?.id ? 'Edit University' : 'Add New University'}
        open={props?.open}
        handleClose={props?.handleClose}
      >
        {loading ? (
          <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
            <Spinner color="brand.500" />
          </Box>
        ) : (
          <Box>
            <FormControl>
              <Grid rowGap={'20px'}>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Title<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="15"
                    type="text"
                    placeholder="Enter title"
                    size="md"
                    name="title"
                    value={form.title}
                    maxLength={100}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                <GridItem>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email Address<Text color={brandStars}> *</Text>
                  </FormLabel>
                  <Input
                    disabled={form._id ? true : false}
                    isRequired={true}
                    variant="auth"
                    autoComplete="off"
                    fontSize="15"
                    type="email"
                    placeholder="Enter email address"
                    size="md"
                    name="email"
                    value={form.email}
                    maxLength={100}
                    onChange={handleFieldChange}
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    label="Description"
                    variant="auth"
                    placeholder="Enter description"
                    name="description"
                    value={form.description}
                    maxLength={1000}
                    onChange={handleChange}
                  />
                </GridItem>
                <GridItem>
                  {' '}
                  <TextField
                    label="Address"
                    variant="auth"
                    placeholder="Enter address"
                    name="address"
                    value={form.address}
                    maxLength={1000}
                    onChange={handleChange}
                  />
                </GridItem>

                <GridItem>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Status
                  </FormLabel>
                  <Select
                    variant="outline"
                    color={textColor}
                    name="isActive"
                    value={form?.isActive}
                    borderRadius={4}
                    _hover={{
                      cursor: 'pointer',
                    }}
                    _focus={{
                      cursor: 'pointer',
                      outline: 'none',
                    }}
                    _active={{
                      cursor: 'pointer',
                      outline: 'none',
                    }}
                    focusBorderColor="gray.500"
                    onChange={(e) => {
                      form._id && handleSelectChange(e);
                    }}
                  >
                    {statusData.map((data) => (
                      <option
                        key={data.id}
                        value={data.id}
                        style={{ cursor: 'pointer' }}
                      >
                        {data.item}
                      </option>
                    ))}
                  </Select>
                </GridItem>
              </Grid>
            </FormControl>
          </Box>
        )}
        <FooterButtons
          title={props.id ? 'Save Changes' : 'Save'}
          loading={buttonLoading}
          handleCancelButton={props?.handleClose}
          handleClick={handleSubmit}
        />
      </ModalTemplate>
    </>
  );
}

export default Add;
const statusData = [
  {
    id: ENUM_USER_STATUS.ACTIVE,
    item: `Active`,
  },
  {
    id: ENUM_USER_STATUS.INACTIVE,
    item: `In Active`,
  },
];
