import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { post, get, putType } from '../../utils/request';
import {
  selectForm,
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectSortColumn,
  selectSortDir,
} from './selector';
import CatchBlockFunction from 'components/hooks/CatchError';
import { ENUM_USER_STATUS, IUniversity } from './types';
import { toast } from 'sonner';
import { emailRegex } from 'utils/constants/Email';

export function* doAddRequest(action: { payload: { callback: any } }) {
  yield delay(500);
  const form: IUniversity = yield select(selectForm);

  if (form.title.length == 0) {
    toast.error('Please enter title');
    return;
  }
  if (form.email.length == 0) {
    toast.error('Please enter email');
    return;
  }

  if (form.email.length > 0) {
    if (!emailRegex.test(form.email)) {
      toast.error('Please enter valid email');
      return;
    }
  }
  if (form.description.length == 0) {
    toast.error('Please enter description');
    return;
  }
  if (form.address.length == 0) {
    toast.error('Please enter address');
    return;
  }
  let data = {
    title: form.title.trim(),
    email: form.email.trim(),
    description: form.description.trim(),
    address: form.address.trim(),
    isActive: ENUM_USER_STATUS.ACTIVE,
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      post,
      `admin/university/create`,
      data,
    );
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield put(actions.setButtonLoading(false));
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `admin/university/list?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetEditRequest(action: { payload: string }) {
  yield delay(500);

  try {
    yield put(actions.setEditLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `admin/university/get/${action.payload}`,
    );
    yield put(actions.setEditLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(actions.setDataForEdit(response.data.data));
  } catch (error: any) {
    yield put(actions.setEditLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateRequest(action: { payload: { callback: any } }) {
  yield delay(500);

  const form: IUniversity = yield select(selectForm);
  if (form.title.length == 0) {
    toast.error('Please enter title');
    return;
  }
  if (form.email.length == 0) {
    toast.error('Please enter email');
    return;
  }

  if (form.email.length > 0) {
    if (!emailRegex.test(form.email)) {
      toast.error('Please enter valid email');
      return;
    }
  }
  if (form.description.length == 0) {
    toast.error('Please enter description');
    return;
  }
  if (form.address.length == 0) {
    toast.error('Please enter address');
    return;
  }
  let data = {
    _id: form._id,
    title: form.title.trim(),
    email: form.email.trim(),
    description: form.description.trim(),
    address: form.address.trim(),
    isActive: form.isActive,
  };
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      putType,
      `admin/university/update/${form._id}`,
      data,
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    toast.success(response.data.message);
    yield call(action?.payload?.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* UniversityRepoSaga() {
  yield takeLatest(actions.doAdd, doAddRequest);
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetEdit, doGetEditRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
}
