import { DashboardState } from '.';

export const initialState: DashboardState = {
  filterValue: 0,
  startDate: new Date(),
  endDate: new Date(),
  DashboardFormData: {
    examCount: 0,
    previousExamCount: 0,
    previousStudentCount: 0,
    previousTotalPayment: 0,
    previousUniversityCount: 0,
    studentCount: 0,
    totalPayment: 0,
    universityCount: 0,
  },
  chartData: {
    date: [],
    exam: [],
    student: [],
    university: [],
  },
};
