import axios from 'axios';
import Api from './Api';

const post = async (url: string, data: any) => {
  return Api.post(url, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const putType = async (url: string, data: any) => {
  return Api.put(url, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const patch = async (url: string, data: any) => {
  return Api.patch(url, data, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const get = async (url: string) => {
  return Api.get(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
const deleteType = async (url: string) => {
  return Api.delete(url, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  });
};
export { post, putType, get, deleteType, patch };
