export interface DashboardState {
  DashboardFormData: DashboardInterface;
  filterValue: DateFilterTypeEnum;
  startDate: Date | string;
  endDate: Date | string;
  chartData: ResponseData;
}
export interface DashboardInterface {
  examCount: number;
  previousExamCount: number;
  previousStudentCount: number;
  previousTotalPayment: number;
  previousUniversityCount: number;
  studentCount: number;
  totalPayment: number;
  universityCount: number;
}
export interface ResponseData {
  date: string[]; // Array of month names
  exam: number[]; // Array of numbers representing plan purchases per month
  student: number[]; // Array of numbers representing user data per month
  university: number[]; // Array of numbers representing user data per month
}
export enum DateFilterTypeEnum {
  NOTHING = -1,
  ALL = 0,
  TODAY = 1,
  WEEK = 7,
  MONTH = 30,
  YEAR = 365,
}
export type InitialDashboardState = DashboardState;
