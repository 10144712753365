export interface IExam {
  _id: string;
  university_id: string;
  university_title: string;
  title: string;
  isPublished: boolean;
  examDate: string;
  createdAt: string;
  updatedAt: string;
  totalStudents: number;
}


export interface IExamStudent {
  _id: string;
  studentId: string;
  name: string;
  status: number;
  marks: number;
  result: STUDENT_EXAM_RESULT_STATUS_ENUM;
  hallTicketDownload: boolean;
  admissionCompleted: boolean;
  createdAt: string;
  updatedAt: string;
  isSpecialStudent: boolean;
  examId: string;
}
export interface IExamPayment {
  _id: string;
  paymentNumber: string;
  paymentId: string;
  name: string;
  email: string;
  amount: number;
  status: number;
}
export enum STUDENT_EXAM_RESULT_STATUS_ENUM {
  PASS = 1,
  FAIL = 2,
  NOT_DECLARED = 3,
}

//details *************
export interface IRegistrationDetails {
  _id: string;
  examId: string;
  university: string;
  studentId: string;
  name1: string;
  name2: string;
  address: string;
  profilePic: string;
  nameOfHighSchool: string;
  department: string;
  dateOfGraduation: string;
  dateOfBirth: string;
  additionalFieldsData: IRegistrationAdditionalFieldsDetails[];
  createdAt: string;
}

export interface IRegistrationAdditionalFieldsDetails {
  _id: string;
  createdAt: string;
  updatedAt: string;
  university: string;
  studentId: string;
  examId: string;
  fieldId: string;
  title: string;
  description: string;
  type: ENUM_ADDITIONAL_FIELD_TYPE;
  options: [];
  fileSelectFileType: ENUM_FILE_TYPE;
  maxNoOfFiles: number;
  values: any;
}
export interface IUploadDocumentFieldsDataDetails {
  _id: string;
  createdAt: string;
  updatedAt: string;
  university: string;
  studentId: string;
  examId: string;
  fieldId: string;
  title: string;
  description: string;
  options: [];
  fileSelectFileType: ENUM_FILE_TYPE;
  maxNoOfFiles: number;
  values: any;
}
export interface ISubmissionMailing {
  _id: string;
  schoolAddressFieldsData: IRegistrationAdditionalFieldsDetails[];
  uploadDocumentFieldsData: IUploadDocumentFieldsDataDetails[];
  createdAt: string;
}
export interface IFeeDetails {
  _id: string;
  feeLastDate: string;
  title: string;
  amount: number;
}
export interface IExamDateDetails {
  _id: string;
  examDate: string;
  examPlace: string;
  examDateRemark: string;
}
export interface IExamResult {
  _id: string;
  title: string;
  marks: number;
  resultContent: string;
}
export interface IPaymentDetails {
  _id: string;
  paymentId: string;
  amount: number;
  paymentMethod: PaymentTypeEnum;
}
export enum PaymentTypeEnum {
  CARD = 1,
  DEPT = 2,
}
export enum ENUM_ADDITIONAL_FIELD_TYPE {
  TEXT = 'TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  SELECT_YES_NO = 'SELECT_YES_NO',
  FILE_SELECT = 'FILE_SELECT',
  TEXT_AREA = 'TEXT_AREA',
  SINGLE_SELECT = 'SINGLE_SELECT',
}

export enum ENUM_FILE_TYPE {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  DOC = 'DOC',
}
export interface ExamState {
  list: IExam[];
  studentList: IExamStudent[];
  paymentList: IExamPayment[];
  loading: boolean;
  pageNo: number;
  pageSize: number;
  totalRow: number;
  search: string;
  sortColumn: string;
  sortDir: string;
  buttonLoading: boolean;
  studentPageNo: number;
  studentPageSize: number;
  studentTotalRow: number;
  studentSortColumn: string;
  studentSortDir: string;

  //details
  registrationDetails: IRegistrationDetails;
  submissionMailing: ISubmissionMailing;
  resultLoading: boolean;
  feeLoading: boolean;
  examDateLoading: boolean;
  examDateDetails: IExamDateDetails;
  feeDetails: IFeeDetails;
  examResult: IExamResult;
  paymentDetails: IPaymentDetails;
}

export type InitialState = ExamState;
