import { ExamState, PaymentTypeEnum } from '.';

export const initialState: ExamState = {
  loading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  list: [],
  sortColumn: '',
  sortDir: '',
  buttonLoading: false,
  studentList: [],
  studentPageNo: 1,
  studentPageSize: 10,
  studentTotalRow: 0,
  studentSortColumn: '',
  studentSortDir: '',
  paymentList: [],
  registrationDetails: {
    _id: '',
    examId: '',
    university: '',
    studentId: '',
    name1: '',
    name2: '',
    address: '',
    profilePic: '',
    nameOfHighSchool: '',
    department: '',
    dateOfGraduation: '',
    dateOfBirth: '',
    additionalFieldsData: [],
    createdAt: '',
  },
  submissionMailing: {
    _id: '',
    schoolAddressFieldsData: [],
    uploadDocumentFieldsData: [],
    createdAt: '',
  },
  resultLoading: false,
  feeLoading: false,
  examDateLoading: false,

  feeDetails: {
    _id: '',
    feeLastDate: '',
    title: '',
    amount: 0,
  },
  examDateDetails: {
    _id: '',
    examDate: '',
    examPlace: '',
    examDateRemark: '',
  },
  examResult: {
    _id: '',
    title: '',
    marks: 0,
    resultContent: '',
  },
  paymentDetails: {
    _id: '',
    paymentId: '',
    amount: 0,
    paymentMethod: PaymentTypeEnum.CARD,
  },
};
