/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';
import { useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  Image,
} from '@chakra-ui/react';
// Assets
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectForm,
  selectLoading,
  selectTogglePassword,
} from '../../../Redux/Login/selector';
import { actions } from '../../../Redux/Login/slice';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const handleShow = () => {
    dispatch(actions.toggleShowPassword());
  };

  const form = useSelector(selectForm);
  const show = useSelector(selectTogglePassword);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateFormValue({ key: name, value: value }));
  };
  const handleSubmit = () => {
    dispatch(
      actions.doLogin({
        callback(token) {
          dispatch(actions.clearFrom());
          dispatch(
            actions.doGetProfile({
              token: token,
              callback: () => {
                navigate('/admin/dashboards');
              },
            }),
          );
        },
      }),
    );
  };

  return (
    <>
      <Flex
        justifyContent={'center'}
        flexDirection={'column'}
        minHeight={'100vh'}
      >
        {/* <Flex justifyContent={'center'} alignItems={'center'}>
          <Image
            src={ContrastBlockDark}
            width={{ sm: '200px', md: 'auto' }}
            color="inherit"
          />
        </Flex> */}
        <CenteredAuth
          cardTop={{ base: '40px' }}
          cardBottom={{ base: '50px', lg: 'auto' }}
          // image={ContrastBlockDark}
          mx="0px"
          width={{ sm: '100%', md: 'max-content' }}
        >
          <Flex
            maxW={{ base: '100%', md: 'max-content' }}
            w="100%"
            mx={{ base: 'auto', lg: '0px' }}
            me="auto"
            justifyContent="center"
            flexDirection="column"
          >
            <Box>
              <Heading
                color={textColor}
                fontSize="22px"
                fontWeight={500}
                mb="36px"
              >
                Admin Panel - Sign In
              </Heading>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: '100%', md: '420px' }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: 'auto', lg: 'unset' }}
              me="auto"
              mb={{ base: '20px', md: 'auto' }}
            >
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email Address<Text color={brandStars}> *</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  type="email"
                  placeholder="Enter email address"
                  mb="16px"
                  fontWeight="500"
                  size="md"
                  autoComplete="off"
                  name="email"
                  value={form.email}
                  onChange={handleFieldChange}
                />
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  Password<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    isRequired={true}
                    fontSize="sm"
                    placeholder="Enter password"
                    mb="16px"
                    size="md"
                    autoComplete="off"
                    type={show ? 'text' : 'password'}
                    variant="auth"
                    name="password"
                    value={form.password}
                    onChange={handleFieldChange}
                  />
                  <InputRightElement
                    display="flex"
                    alignItems="center"
                    mt="4px"
                  >
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleShow}
                    />
                  </InputRightElement>
                </InputGroup>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  onClick={handleSubmit}
                  isLoading={loading}
                  isDisabled={loading}
                >
                  Sign In
                </Button>
              </FormControl>
            </Flex>
          </Flex>
        </CenteredAuth>
      </Flex>
    </>
  );
}

export default SignIn;
