import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import {
  IExam,
  IExamDateDetails,
  IExamPayment,
  IExamResult,
  IExamStudent,
  IFeeDetails,
  IPaymentDetails,
  IRegistrationDetails,
  ISubmissionMailing,
} from './types';

export const useClinicSlice = createSlice({
  name: 'examState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setTotalRow: (state, action: PayloadAction<number>) => {
      state.totalRow = action.payload;
    },
    setSortColumn: (state, action: PayloadAction<string>) => {
      state.sortColumn = action.payload;
    },
    setSortDir: (state, action: PayloadAction<string>) => {
      state.sortDir = action.payload;
    },
    setStudentPageSize: (state, action: PayloadAction<number>) => {
      state.studentPageSize = action.payload;
    },
    setStudentPageNo: (state, action: PayloadAction<number>) => {
      state.studentPageNo = action.payload;
    },
    setStudentTotalRow: (state, action: PayloadAction<number>) => {
      state.studentTotalRow = action.payload;
    },
    setStudentSortColumn: (state, action: PayloadAction<string>) => {
      state.studentSortColumn = action.payload;
    },
    setStudentSortDir: (state, action: PayloadAction<string>) => {
      state.studentSortDir = action.payload;
    },

    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },

    doGetList: (state) => {
      state.loading = true;
    },
    doGetStudentList: (state, action: PayloadAction<{ id: string }>) => {
      state.loading = true;
    },
    doUpdateSpecialStudent: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {
      state.buttonLoading = true;
    },

    setList: (state, action: PayloadAction<Array<IExam>>) => {
      state.list = action.payload;
    },
    doGetExamPaymentList: (state, action: PayloadAction<{ id: string }>) => {
      state.loading = true;
    },

    setPaymentList: (state, action: PayloadAction<Array<IExamPayment>>) => {
      state.paymentList = action.payload;
    },
    setStudentList: (state, action: PayloadAction<Array<IExamStudent>>) => {
      state.studentList = action.payload;
    },

    //*************** */

    doGetRegistrationDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {
      state.loading = true;
    },
    setRegistrationDetails: (
      state,
      action: PayloadAction<IRegistrationDetails>,
    ) => {
      state.registrationDetails = action.payload;
    },

    doGetSubmissionMailingDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {
      state.buttonLoading = true;
    },
    setSubmissionMailingDetails: (
      state,
      action: PayloadAction<ISubmissionMailing>,
    ) => {
      state.submissionMailing = action.payload;
    },
    doGetFeeDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {
      state.feeLoading = true;
    },
    doGetExamDateDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {
      state.examDateLoading = true;
    },
    doGetResultDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {
      state.resultLoading = true;
    },
    doGetPaymentDetails: (
      state,
      action: PayloadAction<{ id: string; callback: () => void }>,
    ) => {
      state.resultLoading = true;
    },
    setResultLoading: (state, action: PayloadAction<boolean>) => {
      state.resultLoading = action.payload;
    },
    setFeeLoading: (state, action: PayloadAction<boolean>) => {
      state.feeLoading = action.payload;
    },
    setExamDateLoading: (state, action: PayloadAction<boolean>) => {
      state.examDateLoading = action.payload;
    },
    setExamDateDetails: (state, action: PayloadAction<IExamDateDetails>) => {
      state.examDateDetails = action.payload;
    },
    setFeeDetails: (state, action: PayloadAction<IFeeDetails>) => {
      state.feeDetails = action.payload;
    },
    setExamResult: (state, action: PayloadAction<IExamResult>) => {
      state.examResult = action.payload;
    },
    setPaymentDetails: (state, action: PayloadAction<IPaymentDetails>) => {
      state.paymentDetails = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
