import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './types/initialState';
import { RootState } from '../../typesNew';

const selectDomain = (state: RootState) => {
  if (state && state.examState) {
    return state.examState;
  } else {
    return initialState;
  }
};

export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading,
);
export const selectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo,
);
export const selectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize,
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow,
);
export const selectSearch = createSelector(
  [selectDomain],
  (state) => state.search,
);
export const selectSortDir = createSelector(
  [selectDomain],
  (state) => state.sortDir,
);
export const selectSortColumn = createSelector(
  [selectDomain],
  (state) => state.sortColumn,
);

export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading,
);
export const selectStudentList = createSelector(
  [selectDomain],
  (state) => state.studentList,
);
export const selectStudentPageNo = createSelector(
  [selectDomain],
  (state) => state.studentPageNo,
);
export const selectStudentPageSize = createSelector(
  [selectDomain],
  (state) => state.studentPageSize,
);
export const selectStudentTotalRow = createSelector(
  [selectDomain],
  (state) => state.studentTotalRow,
);

export const selectStudentSortDir = createSelector(
  [selectDomain],
  (state) => state.studentSortDir,
);
export const selectStudentSortColumn = createSelector(
  [selectDomain],
  (state) => state.studentSortColumn,
);
export const selectPaymentList = createSelector(
  [selectDomain],
  (state) => state.paymentList,
);
export const selectRegistrationDetails = createSelector(
  [selectDomain],
  (state) => state.registrationDetails,
);
export const selectSubmissionMailing = createSelector(
  [selectDomain],
  (state) => state.submissionMailing,
);
export const selectResultLoading = createSelector(
  [selectDomain],
  (state) => state.resultLoading,
);
export const selectFeeLoading = createSelector(
  [selectDomain],
  (state) => state.feeLoading,
);
export const selectExamDateLoading = createSelector(
  [selectDomain],
  (state) => state.examDateLoading,
);
export const selectExamDateDetailsData = createSelector(
  [selectDomain],
  (state) => state.examDateDetails,
);
export const selectExamResultDetailsData = createSelector(
  [selectDomain],
  (state) => state.examResult,
);
export const selectExamFeeDetailsData = createSelector(
  [selectDomain],
  (state) => state.feeDetails,
);
export const selectExamPaymentDetailsData = createSelector(
  [selectDomain],
  (state) => state.paymentDetails,
);
