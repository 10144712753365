import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Stack,
  Box,
  FormControl,
  Select,
  Spinner,
  useBreakpointValue,
  ResponsiveValue,
} from '@chakra-ui/react';
// Custom components
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import * as React from 'react';
// Assets
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { IExamPayment } from '../../Redux/Exam/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLoading,
  selectSearch,
  selectPageSize,
  selectPageNo,
  selectTotalRow,
  selectSortColumn,
  selectSortDir,
} from '../../Redux/Exam/selector';
import { actions } from '../../Redux/Exam/slice';
import dateFormat from 'components/hooks/dateFormat';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import CurrencyConvertor from 'components/CurrencyConvertor';

type Props = { rData: IExamPayment[] };

const List: React.FC<Props> = ({ rData }) => {
  const pagesize = useSelector(selectPageSize);
  const pageNo = useSelector(selectPageNo);
  const loading = useSelector(selectLoading);
  const totalCount = useSelector(selectTotalRow);
  const SortColumn = useSelector(selectSortColumn);
  const SortDir = useSelector(selectSortDir);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  React.useEffect(() => {
    table.getHeaderGroups();
    getPaginationRowModel();
    return () => {};
  }, [rData]);
  useEffect(() => {
    if (params.id) {
      dispatch(actions.setSearch(''));
      dispatch(actions.doGetExamPaymentList({ id: params.id }));
    }
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(actions.doGetExamPaymentList({ id: params.id }));

    return () => {};
  }, [pageNo, SortColumn, SortDir, pagesize]);

  const handleSpecialStudent = (e: any, id: string) => {};

  const textColor = useColorModeValue('brand.500', 'white');
  const borderColor = useColorModeValue('secondaryGray.600', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');

  const [globalFilter, setGlobalFilter] = React.useState('');
  const columnHelper: any = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name',

      cell: (info: any) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>
              {info.getValue()?.length > 40
                ? info.getValue()?.slice(0, 40)
                : info.getValue()}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('email', {
      id: 'email',
      enableSorting: true,
      header: 'Email',
      sortDescFirst: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor('amount', {
      id: 'amount',
      header: 'Amount',

      cell: (info: any) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <CurrencyConvertor value={Number(info.getValue())} />
          </Flex>
        );
      },
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: 'Payment Date',
      cell: (info: any) => {
        return (
          <Flex w="100%" justify="start" align={'center'}>
            <Text marginLeft={2}>{dateFormat(info?.getValue())}</Text>
          </Flex>
        );
      },
    }),
  ];
  const [sorting, setSorting] = React.useState([]);
  const table = useReactTable({
    data: rData,
    columns: columns,
    state: {
      globalFilter,
      sorting,
    },
    pageCount: Math.ceil(totalCount / pagesize),
    sortDescFirst: true,
    enableMultiSort: true,
    manualSorting: true,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  React.useEffect(() => {
    if (sorting.length > 0) {
      dispatch(actions.setSortColumn(sorting[0].id));
      dispatch(actions.setSortDir(sorting[0].desc ? 'desc' : 'asc'));
    }
    return () => {};
  }, [sorting]);
  const createPages = (
    count: number,
    currentPage: number,
    maxButtons: number,
  ) => {
    let arrPageCount: any = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
    let endPage = Math.min(startPage + maxButtons - 1, count);
    startPage = Math.max(endPage - maxButtons + 1, 1);

    for (let i = startPage; i <= endPage; i++) {
      arrPageCount.push(i);
    }

    if (startPage > 1) {
      arrPageCount.unshift('...');
    }
    if (endPage < count) {
      arrPageCount.push('...');
    }

    return arrPageCount;
  };

  // In your component
  const pageCount = table.getPageCount();
  const pages = createPages(pageCount, pageNo, 2);
  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name') {
        table.setSorting([{ id: 'name', desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);
  console.log('Disabled:', pageNo === 1 || rData.length === 0);
  const flexDirection: ResponsiveValue<'row' | 'column'> = useBreakpointValue({
    base: 'column',
    md: 'row',
  });
  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex align={'center'} justify={'space-between'} w="100%" mb="28px">
        <DebouncedInput
          className="p-2 font-lg shadow border border-block"
          placeholder="Search..."
        />
      </Flex>
      {loading ? (
        <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <>
          <Box overflowX="auto" mb="24px">
            {table.getRowModel().rows.length > 0 ? (
              <Table variant="simple" color="gray.500" minWidth="600px">
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                      {headerGroup.headers.map((header: any) => {
                        return (
                          <Th
                            pe="10px"
                            borderColor={borderColor}
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{
                              fontWeight: 500,
                              cursor: 'pointer',
                              textTransform: 'capitalize',
                              color: textColor,
                            }}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                          </Th>
                        );
                      })}
                    </Tr>
                  ))}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map((row: any) => {
                    return (
                      <Tr px="20px" key={row.id}>
                        {row.getVisibleCells().map((cell: any) => {
                          return (
                            <Td
                              key={cell.id}
                              color={textColor}
                              fontSize={{ sm: '12px', md: '14px' }}
                              minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                              borderColor={borderColor}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : (
              <Text
                fontSize="sm"
                color={textColor}
                fontWeight="normal"
                textAlign={'center'}
                marginRight={20}
              >
                {' '}
                No Records Available
              </Text>
            )}
          </Box>
          {rData.length != 0 && !loading && (
            <Flex
              w="100%"
              direction={flexDirection}
              justify="space-between"
              align={'center'}
              px="20px"
              pt="10px"
              pb="5px"
            >
              <Box>
                <Stack
                  direction="row"
                  alignSelf="flex-end"
                  alignItems={'center'}
                  spacing="4px"
                  ms="auto"
                >
                  <Text
                    fontSize="sm"
                    color={textColor}
                    fontWeight="normal"
                    marginRight={2}
                  >
                    Show
                  </Text>
                  <FormControl
                    style={{
                      marginLeft: 0,
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Select
                      variant="outline"
                      color={textColor}
                      borderWidth={0.5}
                      width={20}
                      height={8}
                      value={pagesize}
                      borderRadius={4}
                      _hover={{
                        cursor: 'pointer',
                      }}
                      _focus={{
                        cursor: 'pointer',
                        outline: 'none',
                      }}
                      _active={{
                        cursor: 'pointer',
                        outline: 'none',
                      }}
                      focusBorderColor="gray.500"
                      onChange={(e) => {
                        dispatch(actions.setPageNo(1));
                        dispatch(actions.setPageSize(Number(e.target.value)));
                      }}
                    >
                      {[5, 10, 20, 40, 50, 100, 200].map((data) => (
                        <option style={{ cursor: 'pointer' }}>{data}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <Text
                    fontSize="sm"
                    color={textColor}
                    fontWeight="normal"
                    marginLeft={2}
                  >
                    entries
                  </Text>
                </Stack>
              </Box>
              <div className="flex items-center gap-2">
                <Stack
                  direction="row"
                  alignSelf="flex-end"
                  spacing="4px"
                  ms="auto"
                >
                  <Button
                    variant="no-effects"
                    onClick={() => {
                      if (pageNo === 1 || rData.length === 0) {
                        return;
                      }
                      dispatch(actions.setPageNo(pageNo - 1));
                    }}
                    disabled={pageNo === 1 || rData.length === 0}
                    transition="all .5s ease"
                    color={
                      pageNo === 1 || rData.length === 0 ? 'gray' : textColor
                    }
                    isDisabled={pageNo === 1 || rData.length === 0}
                    fontWeight={400}
                    fontSize={14}
                    h="28px"
                    borderRadius="8px"
                    bg="transparent"
                  >
                    <Icon
                      as={MdChevronLeft}
                      w="26px"
                      h="26px"
                      color={textColor}
                    />
                    Previous
                  </Button>

                  {Array.isArray(pages) &&
                    pages.map((pageNumber, index) => {
                      return (
                        <Button
                          variant="no-effects"
                          transition="all .5s ease"
                          onClick={() => {
                            if (pageNumber !== '...') {
                              dispatch(actions.setPageNo(Number(pageNumber)));
                            }
                          }}
                          w="28px"
                          h="28px"
                          minW={'auto'}
                          borderRadius="8px"
                          padding={'6px'}
                          fontWeight={400}
                          bg={
                            pageNumber === pageNo ? brandColor : 'transparent'
                          }
                          key={index}
                        >
                          <Text
                            fontSize="sm"
                            color={pageNumber === pageNo ? '#fff' : textColor}
                          >
                            {pageNumber}
                          </Text>
                        </Button>
                      );
                    })}
                  <Button
                    variant="no-effects"
                    onClick={() => {
                      if (pageNo >= Math.ceil(totalCount / pagesize)) {
                        return;
                      }
                      dispatch(actions.setPageNo(pageNo + 1));
                    }}
                    disabled={pageNo >= Math.ceil(totalCount / pagesize)}
                    transition="all .5s ease"
                    color={
                      pageNo >= Math.ceil(totalCount / pagesize)
                        ? 'gray'
                        : textColor
                    }
                    isDisabled={pageNo >= Math.ceil(totalCount / pagesize)}
                    fontWeight={400}
                    fontSize={14}
                    h="28px"
                    borderRadius="8px"
                    bg="transparent"
                  >
                    Next
                    <Icon
                      as={MdChevronRight}
                      w="26px"
                      h="26px"
                      color={textColor}
                    />
                  </Button>
                </Stack>
              </div>

              <Text
                fontSize="sm"
                color={textColor}
                fontWeight="normal"
                mb={{ sm: '24px', md: '0px' }}
              >
                Page {pageNo} to{' '}
                {pageNo > 1
                  ? pageNo * pagesize > totalCount
                    ? totalCount
                    : pageNo * pagesize
                  : pagesize}{' '}
                of {totalCount}
              </Text>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

export default List;
function DebouncedInput({
  debounce = 500,
}: {
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const dispatch = useDispatch();
  const search = useSelector(selectSearch);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (search.length > 0) {
      dispatch(actions.setPageNo(1));
    }
    return () => {};
  }, [search]);
  const handleChange = (e: any) => {
    dispatch(actions.setSearch(e.target.value));
  };
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <>
      <SearchBar
        value={search}
        onChange={handleChange}
        h="36px"
        w={{ sm: '190px', lg: '280px' }}
        borderRadius="4px"
      />
      <Button
        fontSize="sm"
        variant="outline"
        fontWeight="400"
        h="40px"
        onClick={handleBack}
      >
        Cancel
      </Button>
    </>
  );
}
