import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import { AxiosResponse } from 'axios';
import { get, patch } from '../../utils/request';
import {
  selectPageNo,
  selectPageSize,
  selectSearch,
  selectSortColumn,
  selectSortDir,
  selectStudentPageNo,
  selectStudentPageSize,
  selectStudentSortColumn,
  selectStudentSortDir,
} from './selector';
import CatchBlockFunction from 'components/hooks/CatchError';
import { toast } from 'sonner';

export function* doGetListRequest() {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/list?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }

    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetStudentListRequest(action: { payload: { id: string } }) {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectStudentPageNo);
    const pageSize: number = yield select(selectStudentPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectStudentSortColumn);
    const SortDir: string = yield select(selectStudentSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/get/registered/student/list/${
        action.payload.id
      }?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setStudentTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setStudentList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doUpdateSpecialStudentRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  try {
    yield put(actions.setButtonLoading(true));
    const response: AxiosResponse = yield call(
      patch,
      `admin/exam/student/make/special/${action.payload.id}`,
      {},
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}

export function* doGetExamPaymentListRequest(action: {
  payload: { id: string };
}) {
  yield delay(500);
  try {
    const pageNo: number = yield select(selectPageNo);
    const pageSize: number = yield select(selectPageSize);
    const searchText: string = yield select(selectSearch);
    const SortColumn: string = yield select(selectSortColumn);
    const SortDir: string = yield select(selectSortDir);
    yield put(actions.setLoading(true));
    const response: AxiosResponse = yield call(
      get,
      `payment/admin/list/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ''
      }?pageNo=${pageNo}&pageSize=${pageSize}${
        searchText.length > 0 ? '&search=' + searchText : ''
      }${SortColumn.length > 0 ? '&sortColumn=' + SortColumn : ''}${
        SortDir.length > 0 ? '&sortDir=' + SortDir : ''
      }`,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(
      actions.setTotalRow(
        response.data.data.meta ? response.data.data.meta.total : 0,
      ),
    );
    yield put(actions.setPaymentList(response.data.data.results));
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}

//
export function* doGetRegistrationDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/get/registration/data/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ''
      }`,
    );
    yield put(actions.setLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setRegistrationDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetSubmissionMailingDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setButtonLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/get/submission-mailing/data/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ''
      }`,
    );
    yield put(actions.setButtonLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setSubmissionMailingDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setButtonLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetFeeDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setFeeLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/get/fee-detail/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ''
      }`,
    );
    yield put(actions.setFeeLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setFeeDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setFeeLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetExamDateDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setExamDateLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/get/place-date/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ''
      }`,
    );
    yield put(actions.setExamDateLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamDateDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setExamDateLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetResultDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setResultLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/get/result/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ''
      }`,
    );
    yield put(actions.setResultLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setExamResult(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setResultLoading(false));
    CatchBlockFunction(error);
  }
}
export function* doGetPaymentDetailsRequest(action: {
  payload: { id: string; callback: any };
}) {
  yield delay(500);
  yield put(actions.setResultLoading(true));
  try {
    const response: AxiosResponse = yield call(
      get,
      `admin/exam/get/payment/${
        action.payload.id !== undefined && action.payload.id !== null
          ? action.payload.id
          : ''
      }`,
    );
    yield put(actions.setResultLoading(false));
    if (response && !response.data) {
      toast.error(response.data.message);
      return;
    }
    yield put(actions.setPaymentDetails(response.data.data));
    yield call(action.payload.callback());
  } catch (error: any) {
    yield put(actions.setResultLoading(false));
    CatchBlockFunction(error);
  }
}

export function* ExamRepoSaga() {
  yield takeLatest(actions.doGetList, doGetListRequest);
  yield takeLatest(actions.doGetStudentList, doGetStudentListRequest);
  yield takeLatest(
    actions.doUpdateSpecialStudent,
    doUpdateSpecialStudentRequest,
  );
  yield takeLatest(actions.doGetExamPaymentList, doGetExamPaymentListRequest);

  //************* */
  yield takeLatest(
    actions.doGetRegistrationDetails,
    doGetRegistrationDetailsRequest,
  );
  yield takeLatest(
    actions.doGetSubmissionMailingDetails,
    doGetSubmissionMailingDetailsRequest,
  );
  yield takeLatest(actions.doGetFeeDetails, doGetFeeDetailsRequest);
  yield takeLatest(actions.doGetExamDateDetails, doGetExamDateDetailsRequest);
  yield takeLatest(actions.doGetResultDetails, doGetResultDetailsRequest);
  yield takeLatest(actions.doGetPaymentDetails, doGetPaymentDetailsRequest);
}
