import { Icon, Image } from '@chakra-ui/react';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
//icons
import Team from 'assets/img/newimages/People_Community.svg';

import { FaHome, FaUniversity, FaClipboard } from 'react-icons/fa';

//Custom

import StudentList from 'views/StudentList';
import ExamList from 'views/ExamList';
import University from 'views/UniversityList';
import PaymentList from 'views/PaymentList';
import Details from 'views/ExamList/Details';
const handleLogout = () => {
  localStorage.clear();
};
const routes: any = [
  // --- Dashboards ---
  {
    name: 'Dashboards',
    layout: '/admin',
    visibleSidebar: true,
    path: '/dashboards',
    icon: <Icon as={FaHome} w={5} h={5} />,
    component: <DashboardsDefault />,
  },
  {
    name: 'Manage University',
    layout: '/admin',
    visibleSidebar: true,
    path: '/university-list',
    icon: <Icon as={FaUniversity} w={5} h={5} />,
    component: <University />,
  },
  {
    name: 'Manage Exam',
    layout: '/admin',
    visibleSidebar: true,
    path: '/exam-list',
    icon: <Icon as={FaClipboard} w={5} h={5} />,
    component: <ExamList />,
  },
  {
    name: 'Manage Student',
    layout: '/admin',
    visibleSidebar: false,
    path: '/student-list/:id',
    icon: <Image src={Team} width="25px" height="25px" color="inherit" />,
    component: <StudentList />,
  },
  {
    name: 'Manage Details',
    layout: '/admin',
    visibleSidebar: false,
    path: '/exam-details/:id/:examId',
    icon: <Image src={Team} width="25px" height="25px" color="inherit" />,
    component: <Details />,
  },
  {
    name: 'Manage payment',
    layout: '/admin',
    visibleSidebar: false,
    path: '/payment-list/:id',
    icon: <Image src={Team} width="25px" height="25px" color="inherit" />,
    component: <PaymentList />,
  },
];

export default routes;
