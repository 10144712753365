/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  InputGroup,
  Icon,
  InputRightElement,
} from '@chakra-ui/react';
import {
  selectLoading,
  selectPassword,
  selectToggleConfirmPassword,
  selectTogglePassword,
} from '../../../Redux/Login/selector';
import { actions } from '../../../Redux/Login/slice';

// Custom components
import CenteredAuth from 'layouts/auth/variants/Centered';
import React from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { passwordRegex } from 'utils/constants/Password';
import { toast } from 'sonner';

function ForgotPassword() {
  // Chakra color mode
  const textColor = useColorModeValue('white', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const handleShow = () => {
    dispatch(actions.toggleShowPassword());
  };
  const handleShowConfirmPassword = () => {
    dispatch(actions.toggleConfirmPassword());
  };

  const form = useSelector(selectPassword);
  const show = useSelector(selectTogglePassword);
  const confirmPassword = useSelector(selectToggleConfirmPassword);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updatePasswordFormValue({ key: name, value: value }));
  };
  const handleSubmit = () => {
    dispatch(
      actions.updateFormValue({
        key: 'password',
        value: form?.password.trim(),
      }),
    );
    dispatch(
      actions.updateFormValue({
        key: 'confirmPassword',
        value: form?.confirmPassword.trim(),
      }),
    );

    if (form.password.length === 0) {
      toast.error('Please enter password');
      return;
    }
    if (form.confirmPassword.length === 0) {
      toast.error('Please enter confirm password');
      return;
    }
    if (form.password.length > 0 && !passwordRegex.test(form.password)) {
      toast.error('Please enter valid password');
      return;
    }
    if (
      form.confirmPassword.length > 0 &&
      !passwordRegex.test(form.confirmPassword)
    ) {
      toast.error('Please enter valid confirm password');
      return;
    }
    if (form?.password !== form?.confirmPassword) {
      toast.error('New and confirm password should be the same');
      return;
    }
    dispatch(
      actions.doUpdatePassword({
        callback() {
          dispatch(actions.clearPasswordFrom());
          dispatch(actions.doGetProfile({ token: '', callback() {} }));
          navigate(-1);
        },
      }),
    );
  };
  return (
    <CenteredAuth
      cardTop={{ base: '140px', md: '24vh' }}
      cardBottom={{ base: '50px', lg: 'auto' }}
    >
      <Flex
        w="100%"
        maxW="max-content"
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        px={{ base: '25px', md: '0px' }}
        flexDirection="column"
      >
        <Box me="auto" mb="34px">
          <Heading
            color={textColor}
            fontSize={{ base: '3xl', md: '36px' }}
            mb="16px"
          >
            Update your password?
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', lg: '456px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
          align="start"
        >
          <FormControl>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                ms={{ base: '0px', md: '4px' }}
                placeholder="Enter password"
                mb="24px"
                size="lg"
                autoComplete="off"
                type={show ? 'text' : 'password'}
                variant="auth"
                name="password"
                value={form.password}
                onChange={handleFieldChange}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleShow}
                />
              </InputRightElement>
            </InputGroup>
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Confirm Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                ms={{ base: '0px', md: '4px' }}
                placeholder="Enter password"
                mb="24px"
                size="lg"
                type={confirmPassword ? 'text' : 'password'}
                variant="auth"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={handleFieldChange}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={confirmPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleShowConfirmPassword}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSubmit}
              isLoading={loading}
              isDisabled={loading}
            >
              Update
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default ForgotPassword;
