import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useSelector } from 'react-redux';
import { selectStudentList } from '../../Redux/Exam/selector';
import List from './List';

function Index() {
  const data = useSelector(selectStudentList);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card>
        <List rData={data} />
      </Card>
    </Flex>
  );
}

export default Index;
