import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectButtonLoading,
  selectSubmissionMailing,
} from '../../Redux/Exam/selector';
import { ENUM_ADDITIONAL_FIELD_TYPE } from '../../Redux/Exam/types';
import { actions } from '../../Redux/Exam/slice';
import { path } from '../../utils/Api';
import { Box, Grid, GridItem, Spinner, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const SubmissionMailingDetails: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const data = useSelector(selectSubmissionMailing);
  const loading = useSelector(selectButtonLoading);
  useEffect(() => {
    if (params?.id) {
      dispatch(
        actions.doGetSubmissionMailingDetails({ id: params.id, callback() {} }),
      );
    }
    return () => {};
  }, []);

  const handleOpen = (data: string) => {
    window.open(`${path()}${data}`);
  };
  return (
    <>
      {loading ? (
        <Box justifyContent={'center'} display={'flex'} mx="auto" my={5}>
          <Spinner color="brand.500" />
        </Box>
      ) : (
        <>
          {data?.schoolAddressFieldsData?.length > 0 && (
            <Grid mt={5} rowGap={{ xs: 2, md: '18px' }}>
              <GridItem>
                <Heading
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    textDecoration: 'underline',
                  }}
                >
                  Submissions and mailings
                </Heading>
              </GridItem>
              <GridItem>
                <Heading
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    textDecoration: 'underline',
                  }}
                >
                  SCHOOL ADDRESS
                </Heading>
              </GridItem>

              {data?.schoolAddressFieldsData?.map((item, index) => {
                return (
                  <Grid sx={gridItemStyle} key={index}>
                    <GridItem>
                      <Heading sx={optionHeadingStyle}>{item.title}</Heading>
                    </GridItem>
                    <Grid templateColumns="repeat(3, 1fr)">
                      {item?.type == ENUM_ADDITIONAL_FIELD_TYPE.TEXT && (
                        <>
                          <HeadingSecond>{item.values}</HeadingSecond>
                        </>
                      )}
                      {item?.type == ENUM_ADDITIONAL_FIELD_TYPE.TEXT_AREA && (
                        <HeadingSecond>{item.values}</HeadingSecond>
                      )}
                      {(item?.type ==
                        ENUM_ADDITIONAL_FIELD_TYPE.SINGLE_SELECT ||
                        item?.type ==
                          ENUM_ADDITIONAL_FIELD_TYPE.SELECT_YES_NO) && (
                        <HeadingSecond>{item?.values}</HeadingSecond>
                      )}
                      {item?.type ==
                        ENUM_ADDITIONAL_FIELD_TYPE.MULTI_SELECT && (
                        <>
                          <HeadingSecond>
                            {item.values.map((ele: any, index: number) => {
                              return (
                                <>
                                  {ele} {index - 1 ? <>,</> : ''}
                                </>
                              );
                            })}
                          </HeadingSecond>
                        </>
                      )}
                      {item?.type == ENUM_ADDITIONAL_FIELD_TYPE.FILE_SELECT && (
                        <UploadedFilesBox>
                          {item.values.map((x: any, i: number) => {
                            return (
                              <>
                                <UploadingFile
                                  key={i}
                                  sx={uploadedFilesStyle1}
                                  onClick={() => handleOpen(x)}
                                >
                                  {x}
                                </UploadingFile>
                              </>
                            );
                          })}
                        </UploadedFilesBox>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
          {data?.uploadDocumentFieldsData?.length > 0 && (
            <Grid mt={5} rowGap={{ xs: 2, md: '18px' }}>
              <GridItem>
                <Heading
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    textDecoration: 'underline',
                  }}
                >
                  UPLOAD DOCUMENT
                </Heading>
              </GridItem>

              {data?.uploadDocumentFieldsData?.map((item, index) => {
                return (
                  <Grid sx={gridItemStyle} key={index} >
                    <GridItem>
                      <Heading sx={optionHeadingStyle}>{item.title}</Heading>
                    </GridItem>
                    <Grid templateColumns="repeat(3, 1fr)">
                      <UploadedFilesBox>
                        {item.values.map((x: any, i: number) => {
                          return (
                            <>
                              <UploadingFile
                                key={i}
                                sx={uploadedFilesStyle1}
                                onClick={() => handleOpen(x)}
                              >
                                {x}{' '}
                              </UploadingFile>
                            </>
                          );
                        })}
                      </UploadedFilesBox>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default SubmissionMailingDetails;

const Heading = styled(Text)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#2E2E2E',
}));
const HeadingSecond = styled(Text)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#5780AD',
}));

const gridItemStyle = {
  borderBottom: '1px solid #DEDEDE',
  paddingBottom: { xs: '9px', md: '15px' },
  alignItems: 'center',
};

const optionHeadingStyle = {
  marginBottom: 2,
};

const UploadingFile = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: '#000',
  padding: '10px 7.8px',
  border: '0.5px solid #E3E3E3',
  borderRadius: 5,
  marginLeft: '15px',
}));

const uploadedFilesStyle1 = {
  borderColor: '#11AF22',
  marginBottom: '5px',
  cursor: 'pointer',
};

const UploadedFilesBox = styled(Text)(({ theme }) => ({
  marginTop: 28.5,
}));
