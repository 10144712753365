import { UniversityState, ENUM_USER_STATUS } from '.';

export const initialState: UniversityState = {
  loading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  list: [],
  form: {
    _id: '',
    isActive: ENUM_USER_STATUS.ACTIVE,
    title: '',
    email: '',
    address: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    user: '',
    seoTitle: '',
    totalStudents: 0,
  },
  sortColumn: '',
  sortDir: '',
  buttonLoading: false,
  addNew: false,
  editLoading: false,
};
