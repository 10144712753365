import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
// Chakra imports
import { authRepoSaga } from './Redux/Login/saga';
import {
  sliceKey as authSliceKey,
  reducer as authReducer,
} from './Redux/Login/slice';

import CancelSubscriptionConfirmation from 'components/CancelSubscriptionConfirmation';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignInCentered from 'views/auth/signIn/SignInCentered';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import SignUpCentered from 'views/auth/signUp/SignUpCentered';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import VerificationCentered from 'views/auth/verification/VerificationCentered';
import LockDefault from 'views/auth/lock/LockDefault';
import LockCentered from 'views/auth/lock/LockCentered';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';

// University State
import {
  sliceKey as UniversitySliceKey,
  reducer as UniversityReducer,
} from './Redux/University/slice';
import { UniversityRepoSaga } from './Redux/University/saga';
// Exam State
import {
  sliceKey as ExamSliceKey,
  reducer as ExamReducer,
} from './Redux/Exam/slice';

import { ExamRepoSaga } from './Redux/Exam/saga'; 
// Exam State
import {
  sliceKey as DashboardSliceKey,
  reducer as DashboardReducer,
} from './Redux/DashBoard/slice';
import { DashboardRepoSaga } from './Redux/DashBoard/saga';
export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  // Auth State
  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  useInjectSaga({ key: authSliceKey, saga: authRepoSaga });
  // University State
  useInjectReducer({ key: UniversitySliceKey, reducer: UniversityReducer });
  useInjectSaga({ key: UniversitySliceKey, saga: UniversityRepoSaga });

  // Exam State
  useInjectReducer({ key: ExamSliceKey, reducer: ExamReducer });
  useInjectSaga({ key: ExamSliceKey, saga: ExamRepoSaga });
  // Exam State
  useInjectReducer({ key: ExamSliceKey, reducer: ExamReducer });
  useInjectSaga({ key: ExamSliceKey, saga: ExamRepoSaga });
  // Dashboard State
  useInjectReducer({ key: DashboardSliceKey, reducer: DashboardReducer });
  useInjectSaga({ key: DashboardSliceKey, saga: DashboardRepoSaga });

  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        {/* <Route path="admin/student-list/:id" element={<StudentList />} /> */}
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
        <Route
          path="/CancelSubscriptionConfirmation"
          element={<CancelSubscriptionConfirmation />}
        />

        <Route path="/auth/sign-in/default" element={<SignInDefault />} />
        <Route path="/auth/login" element={<SignInCentered />} />
        <Route path="/auth/sign-up/default" element={<SignUpDefault />} />
        <Route path="/auth/sign-up/centered" element={<SignUpCentered />} />
        <Route
          path="/auth/verification/default"
          element={<VerificationDefault />}
        />
        <Route
          path="/auth/verification/centered"
          element={<VerificationCentered />}
        />
        <Route path="/auth/lock/default" element={<LockDefault />} />
        <Route path="/auth/lock/centered" element={<LockCentered />} />
        <Route
          path="/auth/forgot-password/default"
          element={<ForgotPasswordDefault />}
        />
        <Route
          path="/auth/forgot-password/centered"
          element={<ForgotPasswordCentered />}
        />
      </Routes>
    </ChakraProvider>
  );
}
